.bookings-container {
  padding: 15px;
  height: 100vh;
}

.top-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 50%
}

.add-booking-button {
  background-color: #FF5A60;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-booking-button:hover {
  background-color: #e14e55;
}

.navigation button {
  background-color: #FF5A60;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bookings-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
  padding-bottom: 50px;
}

.day-row {
  display: flex;
  flex-direction: row;
  min-height: 120px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  align-items: center;
  position: relative;  /* Ensure child elements can use z-index properly */
}

.date-column {
  width: 8%;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  left: 0;
  background-color: #f8f9fa;
}

.icon-summary {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.icon-item {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #555;
}

.minimal-icon {
  margin-right: 5px;
  font-size: 12px;
}

.bookings-columns {
  display: flex;
  gap: 10px;
  width: 90%;
  padding-bottom: 15px;
  padding-top: 15px;
  max-height: 300px;
  position: relative;  /* Ensure booking boxes expand correctly */
  overflow: auto;
}



.booking-box {
  background-color: #4c7ac5;
  padding: 10px;
  color: white;
  border-radius: 8px;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  height: 90px;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-overflow: ellipsis;
  overflow: visible;
  white-space: nowrap;
  position: relative;
}

.booking-box:hover {
  transform: translateY(-3px);
  z-index: 10;  /* Bring to front on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  overflow: visible;  /* Ensure overflow content on hover is visible */
}

.custom-tooltip {
  background-color: black !important;
  color: white !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 8px !important;
  z-index: 9999; /* Ensure tooltip is on top */
  max-width: 250px;
  white-space: pre-line; /* Preserve line breaks for readability */
}

.booking-number,
.property-info,
.client-name,
.booking-time,
.cleaners-required {
  margin: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bookings-date {
  margin: 0;
  font-size: 14px;
}

.separator {
  height: 10px;
  min-width: 10px;
}

.pending-booking {
  animation: pulse 2s infinite;
}

.pending-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FF5A60;
  color: white;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.icon-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #555;
  margin-bottom: 2px;
}

.pending-icon {
  opacity: 0.6;
}

.separator {
  height: 10px;
  margin: 5px 0;
}

.laundry-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #24bec6; /* Green background */
  color: white;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.cancelled-badge, .rescheduled-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #a40000; /* Red background */
  color: white;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.delivery-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FFA500; /* Orange background for the badge */
  color: white;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.unit-display {
  font-size: 20px;
}

.pickup-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FFA500; /* Orange background for the badge */
  color: white;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.pending-summary .icon-item{
  color: #ac6a6c;
}

.date-link {
  color: #121517; /* Blue color for the link */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold for emphasis */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.date-link:hover {
  color: #3498db;  /* Change color on hover */
  text-decoration: underline; /* Underline on hover */
}

.laundry-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 2.2rem;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.search-and-add-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.search-bar {
  width: 60%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.search-bar:focus {
  border-color: #FF5A60;
  outline: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.add-laundry-button {
  background-color: #FF5A60;
  color: white;
  padding: 10px 18px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.add-laundry-button:hover {
  background-color: #e14e54;
}

.status-group {
  margin-bottom: 20px;
}

.status-toggle {
  background-color: #FF5A60; /* Default color for toggle */
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-icon {
  font-size: 1.2rem;
}

.laundry-status-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.laundry-box {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.laundry-box:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.laundry-box p {
  margin: 5px 0;
  color: #555;
}

.laundry-box p:first-child {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.status-label {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #777;
}

.confirm-button {
  background-color: #FF5A60;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.confirm-button:hover {
  background-color: #e14e54;
}

@media (max-width: 768px) {
  .laundry-container {
    padding: 10px;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .search-and-add-container {
    flex-direction: column;
    gap: 15px;
  }

  .search-bar {
    width: 100%;
    font-size: 0.9rem;
  }

  .add-laundry-button {
    width: 100%;
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .status-toggle {
    font-size: 0.9rem;
    padding: 8px 15px;
    border-radius: 6px;
  }

  .toggle-icon {
    font-size: 1rem;
  }

  .laundry-status-group {
    gap: 10px;
  }

  .laundry-box {
    font-size: 0.9rem;
    padding: 15px;
    gap: 6px;
  }

  .laundry-box p:first-child {
    font-size: 1rem;
  }

  .status-label {
    position:unset;
  }

  .laundry-box .confirm-button{
    position: unset;
  }

  .confirm-button {
    font-size: 0.8rem;
    padding: 6px 12px;
    bottom: 8px;
    right: 8px;
  }
}
/* Add Property Form */
.add-property-container {
    margin: 20px auto;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .new-property-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input, select, textarea {
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
  }
  
  textarea {
    resize: vertical;
  }
  
  input[type="checkbox"] {
    width: auto;
  }
  
  button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  /* Toggle switch styling */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
  }

  .custom-sq-container {
    display: flex;
    flex-direction: row;
    gap: 20px
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #007bff;
  }

  .add-property-container .submit-button {
    background-color: #ff5a60;
  }
  
  .add-property-container .submit-button:hover {
    background-color: #ff5a60ee;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  textarea {
    margin-bottom:10px;
  }
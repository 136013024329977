/* General container */
.unique-items-container {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.unique-items-title {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

/* Header section */
.unique-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.unique-items-search-bar {
    width: 60%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.unique-add-item-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.unique-add-item-button:hover {
    background-color: #45a049;
}

/* Table styles */
.unique-items-table-wrapper {
    overflow-x: auto;
}

.unique-items-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.unique-items-table th,
.unique-items-table td {
    text-align: left;
    padding: 12px;
    border: 1px solid #ddd;
}

.unique-items-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.unique-items-table td {
    font-size: 14px;
    color: #555;
}

.unique-item-row:hover {
    background-color: #f1f1f1;
}

/* Loading and error messages */
.unique-items-loading,
.unique-items-error {
    text-align: center;
    font-size: 16px;
    color: #ff5a60;
    margin-top: 20px;
}

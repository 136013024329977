.drivers-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.title {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.content {
    display: flex;
    gap: 30px;
}

.drivers-list {
    width: 30%;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 600px;
    overflow-y: auto;
}

.driver-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: transform 0.2s, border-color 0.2s;
}

.driver-card:hover,
.driver-card.active {
    transform: scale(1.03);
    border-color: #008cba;
}

.driver-name {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.driver-balance {
    font-size: 14px;
    color: #777;
}

.driver-details {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.current-balance {
    font-size: 18px;
    margin-bottom: 20px;
}

.current-balance.positive {
    color: #4CAF50;
    /* Modern green */
}

.current-balance.negative {
    color: #FF5A5F;
    /* Modern red */
}

.driver-balance.positive {
    color: #4CAF50;
    /* Modern green for positive balance */
    font-weight: bold;
}

.driver-balance.negative {
    color: #FF5A5F;
    /* Modern red for negative balance */
    font-weight: bold;
}


.transaction-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.transaction-form input {
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.add-button {
    padding: 10px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
    width: 100%;
}

.add-button.expense {
    background-color: #ff4d4d;
}

.add-button.expense:hover {
    background-color: #ff1a1a;
}

.add-button.deposit {
    background-color: #4CAF50;
}

.add-button.deposit:hover {
    background-color: #45a049;
}

.transactions {
    margin-top: 20px;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 5px;
    color: #333;
    transition: transform 0.2s, background-color 0.2s;
}

.transaction-item.expense {
    background-color: #ffe6e6;
    border-left: 5px solid #ff4d4d;
}

.transaction-item.expense:hover {
    background-color: #ffcccc;
}

.transaction-item.deposit {
    background-color: #e6ffe6;
    border-left: 5px solid #4CAF50;
}

.transaction-item.deposit:hover {
    background-color: #ccffcc;
}

.transaction-item.unapproved {
    background-color: #fff3cd;
    border-left: 5px solid #ff9900;
}

.transaction-item.unapproved:hover {
    background-color: #ffe8a1;
}


.transaction-date {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.transaction-actions {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    width: 40%;
    align-items: center;
}

.transaction-actions button {
    margin-right: 10px;
    padding: 5px 10px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.edit-button {
    background-color: #007bff;
    color: white;
}

.edit-button:hover {
    background-color: #0056b3;
}

.delete-button {
    background-color: #dc3545;
    color: white;
}

.delete-button:hover {
    background-color: #c82333;
}

.approve-button {
    background-color: #28a745;
    color: white;
}

.approve-button:hover {
    background-color: #218838;
}

.receipt-button {
    background-color: #ff8000;
}

.receipt-button:hover {
    background-color: #d56b00;
}


.date-description {
    width: 40%;
}

.main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pending-status {
    color: #ff9900;
    font-weight: bold;
    font-size: 14px;
}

.receipt-preview {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.receipt-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor: pointer;
}

.receipt-image:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
/* General Layout */
.dashboard-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  /* Prevent overflow from the container */
}

.main-content {
  flex-grow: 1;
  padding: 40px;
  background-color: #f5f5f5;
  overflow-y: auto;
  /* Allow vertical scrolling for the main content */
}

.login-logo {
  max-width: 200px;
}

/* Sidebar Styles */
.sidebar {
  width: 140px;
  min-width: 140px;
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align content to the left */
  transition: transform 0.3s ease;
  /* Smooth transition for mobile toggle */
}

.sidebar .logo {
  margin-bottom: 30px;
  text-align: center;
}

.sidebar img {
  max-width: 80%;
  height: auto;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.sidebar .nav-item {
  margin: 15px 0;
  font-size: 14px;
  /* Smaller font size */
  cursor: pointer;
}

.sidebar .nav-item a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  /* Align icons and text */
  gap: 10px;
  /* Space between icon and text */
  padding: 8px 10px;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sidebar .nav-item a:hover {
  background-color: #444;
}

/* Mobile Menu Button */
.mobile-menu-button {
  display: none;
  /* Hidden by default */
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  z-index: 1000;
  /* Ensure it stays above other elements */
}

/* Page Titles */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* General Form Layout */
.add-property-container,
.add-client-container {
  margin: 20px auto;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.new-property-form,
.new-client-form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input,
select,
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

input[type="checkbox"] {
  width: auto;
}

button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Dropdown Search Bar */
.search-client-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.search-client-bar:focus {
  border-color: #007bff;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
}

/* Enhanced Search Bar */
.search-bar {
  width: 100%;
  max-width: 600px;
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  outline: none;
}

.search-bar:focus {
  border-color: #007bff;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
}

/* Responsive Adjustments */
@media (max-width: 768px) {

  /* Sidebar hidden by default on mobile */
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 70%;
    max-width: 280px;
    transform: translateX(-100%);
    /* Hide sidebar */
    z-index: 100;
  }

  .sidebar.open {
    transform: translateX(0);
    /* Show sidebar when toggled */
  }

  /* Show mobile menu button */
  .mobile-menu-button {
    display: block;
    background: white;
  }

  .mobile-menu-button:hover {
    display: block;
    background: white;
  }

  /* Adjust main content padding for mobile */
  .main-content {
    padding: 20px;
  }

  .form-row {
    flex-direction: column;
  }

  .new-client-form input,
  .new-property-form input {
    font-size: 14px;
  }

  .add-property-container,
  .add-client-container {
    padding: 15px;
    max-width: 95%;
  }
}

/* Modal styles */
.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  /* Dark semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
}

.modal-content button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #aaa;
}

.modal-content .close-button:hover {
  color: #000;
}

.submit-button:hover {
  background-color: #0062cb;
}

.icon-container {
  width: 15px;
  /* Set a fixed width */
  height: 24px;
  /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Optional: Add animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  animation: modalFadeIn 0.3s ease;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 200px;
  justify-content: center;
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #FF5A60;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;
  margin: 0 auto 15px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-box p {
  margin-bottom: 35px;
  font-size: 1rem;
  color: #333;
}

.ok-button {
  background-color: #FF5A60;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
}

.ok-button:hover {
  background-color: #e14e54;
}
/* Container Styling */
.packing-process-container {
    max-width: 1000px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

/* Property Info Styling */
.property-info-packing {
    text-align: center;
    padding: 10px 0 20px;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 20px;
}

.property-info-packing h2 {
    font-size: 30px;
    color: #333;
    margin: 0;
}

.property-info-packing p {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0 0;
}

/* Section Titles */
.packing-process-container h2 {
    text-align: center;
    color: #444;
    font-size: 26px;
    margin-bottom: 20px;
}

/* Form Styling */
.report-form, .packed-photo-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Add New Item Fields Styling */
.report-item {
    display: flex;
    flex-wrap: wrap;
    max-width: 950px;
    margin: auto;
    gap: 15px;
    align-items: center;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    background-color: #fafafa;
}

.select-field, .text-field, .file-field {
    flex: 1;
    min-width: 180px;
    max-width: 240px;
}

/* Existing Reports Styling */
.existing-reports {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    margin :auto;
}

.existing-report {
    display: flex;
    max-width: 950px;
    width: -webkit-fill-available;
    margin:auto;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    gap: 15px;
}

.existing-report .report-photo-preview {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.existing-report .report-photo-preview:hover {
    transform: scale(1.1);
}

.existing-report-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.existing-report-content p {
    margin: 0;
    font-size: 15px;
    color: #555;
}

.existing-report-content p span {
    font-weight: bold;
    color: #333;
}

.existing-report-actions {
    display: flex;
    align-items: center;
}

/* Step 2: Column Layout */
.step-2-columns {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.packed-items-column, .stained-items-column {
    flex: 1;
    max-width: 45%;
    text-align: left;
}

.packed-items-list, .stained-items-list {
    list-style-type: disc;
    padding-left: 25px;
    font-size: 17px;
    color: #555;
}

.stained-items-list {
    color: #ae2021;
}

/* Blue Bags Section */
.blue-bags-section {
    margin: 30px 0;
    width: 100%;
    text-align: center;
}

.blue-bags-section label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.blue-bags-section .select-field {
    margin:auto;
}

/* File Field and Submit Button Styling */
.packed-photo-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packed-photo-form p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
    text-align: center;
}

.submit-packed-photo {
    margin-top: 10px;
    padding: 10px 25px;
    font-size: 17px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-packed-photo:hover {
    background-color: #0056b3;
}

/* General Button Styling */
.button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.submit-report, .no-stains-report {
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-report {
    background-color: #007bff;
}

.no-stains-report {
    background-color: #17a2b8;
}

.submit-report:hover {
    background-color: #0056b3;
}

.no-stains-report:hover {
    background-color: #138496;
}

.packed-photo-form .file-field {
    margin-bottom: 50px;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .report-item, .existing-report {
        flex-direction: row;
    }

    .button-group {
        flex-direction: column;
    }

    .step-2-columns {
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }

    .report-item .existing-report{
        width: auto;
    }

    .report-item .submit-report {
        width: 100%;
    }

    .report-item .select-field { 
        width: 100%;
    }


}

/* Container */
.properties-container {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 1100px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
}

/* Search and Button Section */
.search-and-add-container {
  display: flex;
  justify-content: space-between;  /* Space between search bar and button */
  align-items: center;
  margin-bottom: 20px;
}

/* Search Bar */
.search-bar {
  flex: 1;  /* Take up remaining space */
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.search-bar:focus {
  border-color: #007bff;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
}

/* Add Property Button */
.add-property-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-property-button:hover {
  background-color: #0056b3;
}

/* Table */
.properties-table {
  width: 100%;
  border-collapse: collapse;
}

.properties-table th, .properties-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.properties-table th {
  background-color: #f9f9f9;
  color: #333;
  font-weight: bold;
}

.property-row:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.property-row td {
  color: #333;
}

.property-row:hover td {
  color: #007bff;
}

/* Clients List Styles */

/* Container for search bar and button */
.clients-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Search Bar */
.search-bar {
    width: 100%;
    max-width: 600px; /* Increased width for better visual impact */
    padding: 12px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f1f1f1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    outline: none;
}

.search-bar:focus {
    border-color: #007bff;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.15);
}

/* Clients Container */
.clients-container {
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 1100px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
}

/* Clients Table */
.clients-table {
    width: 100%;
    border-collapse: collapse;
}

.clients-table th, 
.clients-table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}

.clients-table th {
    background-color: #f9f9f9;
    color: #333;
    font-weight: bold;
}

.client-row:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.client-row td {
    color: #333;
    font-size: 14px;
}

.client-row:hover td {
    color: #007bff;
}

/* Add New Client Button */
.add-client-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 20px; /* Adds some space between the button and the search bar */
}

.add-client-button:hover {
    background-color: #0056b3;
}

.daily-bookings-container {
  padding: 20px;
}

.category {
  margin-bottom: 30px;
}

.area-title {
  font-size: 16px;
  font-weight: bold;
}

.booking-entry {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.booking-entry h3 {
  margin: 0;
}

.booking-entry p {
  margin: 5px 0;
  font-size: 14px;
}

.daily-bookings-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.daily-bookings-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.daily-bookings-container h3,
h4 {
  text-align: left;
}

.daily-bookings-container h4 {
  color: rgba(0, 0, 0, 0.745);
}

.daily-bookings-container .booking-entry {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  position: relative;
}

.daily-bookings-container .booking-entry h3 a {
  font-size: 18px;
  margin-bottom: 8px;
  color: #2c3e50;
}

.daily-bookings-container .booking-entry p {
  font-size: 14px;
  margin: 7px 0;
  color: #34495e;
  text-align: left;
}

.daily-bookings-container .no-cleaners {
  color: red;
  font-weight: bold;
}

.daily-bookings-container strong {
  font-weight: 600;
}

.daily-bookings-container .driver-title {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 20px;
}

.daily-bookings-container .copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 15px;
  background: linear-gradient(135deg, #4c7ac5, #1f5c99);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.daily-bookings-container .copy-button:hover {
  background: linear-gradient(135deg, #1f5c99, #4c7ac5);
  transform: translateY(-2px);
}

.daily-bookings-container .copy-button:active {
  background: #1f5c99;
  transform: translateY(1px);
}

.cancelled-entry {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding-top: 30px; /* Add space for the badge */
}

.badge-red {
  background-color: #a40000;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -10px; /* Position the badge 10px from the top */
  right: -10px; /* Position the badge 10px from the right */
  z-index: 1; /* Ensure the badge appears above the box content */
}

.badge-orange {
  background-color: #FFA500;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.badge-laundry {
  background-color: #24bec6;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.badge-pending {
  background-color: #FF5A60;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.badge-completed {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.badge-in-process {
  background-color: #f78da7; /* Bootstrap info color */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.quick-edit-button {
  position: absolute;
  color: #7a7a7a;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.quick-edit-button:hover {
  color: #007bff;
}
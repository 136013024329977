

/* Flexbox layout for responsive reports */
.client-reports-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 10px;
}

/* Ensure reports wrap and reduce the number of columns on smaller screens */
@media (max-width: 1024px) {
    .client-reports-grid {
        justify-content: space-around;
    }
}

@media (max-width: 768px) {
    .client-reports-grid {
        justify-content: center;
    }

    .client-report-card {
        width: 180px; /* Slightly smaller for tablets */
    }
}

@media (max-width: 480px) {
    .client-reports-grid {
        flex-direction: column;
        align-items: center;
    }

    .client-report-card {
        width: 90%; /* Cards take up most of the screen width on mobile */
    }
}


/* Each report card maintains a fixed size */
.client-report-card {
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 200px;
}

.client-no-reports {
    font-style: italic;
    text-align: center;
    color: grey;
}

.client-report-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Thumbnail Container */
.client-report-thumbnail {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    background: #eee;
}

/* Image & Video Previews */
.client-report-video-preview,
.client-report-image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* Report Type & Date */
.client-report-type {
    font-weight: bold;
    margin-top: 15px;
    font-size: 12px;
}

.client-report-date {
    font-size: 12px;
    color: #666;
}

/* Comment & Area */
.client-report-comment,
.client-report-area {
    font-size: 12px;
    color: #444;
    margin-top: 5px;
}

/* Full-Size Modal */
.client-report-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.902);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

/* Modal Content */
.client-report-modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}

/* Full-size Video & Image */
.client-report-full-video,
.client-report-full-image {
    max-width: 90%;
    max-height: 60vh;
    border-radius: 5px;
    display: block;
    margin-bottom: 10px;
}

/* Modal Info */
.client-report-modal-info {
    text-align: center;
    font-size: 14px;
    color: #ffffff;
}

.client-30-days {
    text-align: middle;
    font-style: italic;
    font-size: 13px;
    margin-bottom: 15px;
}

/* Smooth fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Main container */
.confirm-laundry-container {
    margin: 40px auto;
    padding: 30px;
    max-width: 900px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
  
.confirm-laundry-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
}
  
.form-group {
    margin-bottom: 20px;
}
  
.form-group label {
    font-size: 14px;
    color: #333;
    font-weight: bold;
}
  
.select-booking, 
.quantity-input, 
textarea {
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
}
  
textarea {
    resize: none;
}
  
/* Lists section */
.confirm-laundry-container .laundry-lists-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
}
  
.list-section {
    width: 100%;
}
  
.laundry-lists-container h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #FF5A60;
    padding-bottom: 10px;
}

.confirm-laundry-container h4{
    text-align: center;
}
  
.item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
  
.item-name {
    font-size: 16px;
    color: #666;
    width: 60%;
}
  
.quantity-input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
}

/* Comment and Submit button */
.comment-field {
    padding: 12px;
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
}
  
.submit-button {
    width: 100%;
    padding: 15px;
    background-color: #FF5A60;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.submit-button:hover {
    background-color: #e04b52;
}

.photo-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.photo-preview {
    position: relative;
    width: 100px;
    height: 100px;
}

.photo-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.photo-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 12px;
    padding: 0;
}

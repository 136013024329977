/* Add Client Form */
.add-client-container {
    margin: 20px auto;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .new-client-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .new-client-form label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .new-client-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .new-client-form button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .new-client-form button:hover {
    background-color: #0056b3;
  }
  
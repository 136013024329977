.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f0f2f5;
  }
  
  .login-card {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .login-card h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .login-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 10px;
  }

  .reset-password-link {
    display: block;
    margin-top: 30px;
    text-align: center;
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .reset-password-link:hover {
    text-decoration: underline;
    color: #0056b3;
  }
  
  
  @media (max-width: 480px) {
    .login-card {
      padding: 30px 20px;
    }
  }
  
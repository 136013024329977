
/* Form Labels */
.modal-content label {
    display: block;
    font-weight: bold;
    margin: 10px 0 5px;
    text-align: left;
}

/* Form Inputs */
.modal-content input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

/* Modal Actions (Buttons) */
.add-user-modal-actions {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

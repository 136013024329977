/* Flexbox layout for responsive reports */
.booking-reports-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 10px;
}

/* Ensure reports wrap and reduce the number of columns on smaller screens */
@media (max-width: 1024px) {
    .booking-reports-grid {
        justify-content: space-around;
    }
}

@media (max-width: 768px) {
    .booking-reports-grid {
        justify-content: center;
    }

    .booking-report-card {
        width: 180px; /* Slightly smaller for tablets */
    }
}

@media (max-width: 480px) {
    .booking-reports-grid {
        flex-direction: column;
        align-items: center;
    }

    .booking-report-card {
        width: 90%; /* Cards take up most of the screen width on mobile */
    }
}


/* Each report card maintains a fixed size */
.booking-report-card {
    display: flex;
    flex-direction: column;
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 200px;
    position: relative;
}

.booking-no-reports {
    font-style: italic;
    text-align: center;
    color: grey;
}

.booking-report-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Thumbnail Container */
.booking-report-thumbnail {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    background: #eee;
}

/* Image & Video Previews */
.booking-report-video-preview,
.booking-report-image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* Report Type & Date */
.booking-report-type {
    font-weight: bold;
    margin-top: 15px;
    font-size: 12px;
}

.booking-report-date {
    font-size: 12px;
    color: #666;
}

/* Comment & Area */
.booking-report-comment,
.booking-report-area, .booking-report-approved {
    font-size: 12px;
    color: #444;
    margin-top: 5px;
}

.approved-text {
    color: green;
    font-weight: bold;
}

.not-approved-text {
    color: red;
    font-weight: bold;
}

/* Full-Size Modal */
.booking-report-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.902);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

/* Modal Content */
.booking-report-modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}

/* Full-size Video & Image */
.booking-report-full-video,
.booking-report-full-image {
    max-width: 90%;
    max-height: 60vh;
    border-radius: 5px;
    display: block;
    margin-bottom: 10px;
}

/* Modal Info */
.booking-report-modal-info {
    text-align: center;
    font-size: 14px;
    color: #ffffff;
}

.booking-30-days {
    text-align: middle;
    font-style: italic;
    font-size: 13px;
    margin-bottom: 15px;
}

.report-edit-icon, .report-cancel-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    color: grey;
}

.report-edit-icon {
    font-size: 12px;
}

.report-edit-select {
    margin-top: 10px;
    margin-bottom: 10px;
}

.report-edit-mode {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.report-edit-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}

.report-edit-buttons button {
    font-size: 14px;
    padding: 8px 10px;
}

.booking-reports-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* Smooth fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.team-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.search-add-container {
  display: flex;
  align-items: center;
}

.search-input {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  transition: border 0.3s ease;
}

.search-input:focus {
  border-color: #3498db;
}

.add-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #2980b9;
}

.role-group {
  margin-bottom: 30px;
}

.role-group h3 {
  font-size: 22px;
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.team-list {
  list-style: none;
  padding: 0;
}

.team-member {
  margin-bottom: 15px;
}

.member-card {
  position: relative; /* To position the edit icon */
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

.member-card:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.member-card h4 {
  margin: 0;
  font-size: 20px;
  color: #34495e;
}

.member-card p {
  margin: 5px 0;
  font-size: 14px;
  color: #7f8c8d;
}

/* Edit icon in the top-right corner */
.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #3498db;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #2980b9;
}

.edit-icon, .copy-icon {
  cursor: pointer;
  margin-left: 8px;
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #3498db;
}

.copy-icon {
  color: #7f8c8d;
}

.copy-icon:hover {
  color: #3498db;
}

.eid-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.eid-section p {
  margin-right: 5px;
}

/* Summary Report Page Styles */

.summary-report-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-report-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #343a40;
  margin-bottom: 20px;
}

.summary-client-name {
  text-align: left;
  font-size: 22px;
  margin-bottom: 10px;
}

.summary-client-name a {
  font-size: 22px;
  font-weight: bold;
  color: #0d3866;
  text-decoration: none;
}

.summary-client-name a:hover {
  text-decoration: underline;
}

.summary-report-filters {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filter-group label {
  font-weight: bold;
  color: #495057;
}

.filter-group input {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 14px;
}

.fetch-report-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.fetch-report-button:hover {
  background-color: #0056b3;
}

.summary-report-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.summary-client-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.summary-property-card {
  margin-top: 15px;
  padding: 10px;
  background: #f1f3f5;
  border-radius: 8px;
}

.summary-property-title {
  font-size: 18px;
  font-weight: bold;
  color: #495057;
  margin-bottom: 10px;
}

.summary-bookings-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.summary-booking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #e9ecef;
  margin-bottom: 8px;
}

.summary-booking-id {
  font-weight: bold;
}

.summary-booking-id a {
  font-weight: bold;
  color: #343a40;
  text-decoration: none;
}

.summary-booking-id a:hover {
  font-weight: bold;
  color: #343a40;
  text-decoration: underline;
}

.summary-booking-details {
  display: flex;
  gap: 15px;
}

.summary-booking-details span {
  font-size: 14px;
  color: #6c757d;
}

.summary-booking-details a {
  font-size: 14px;
  color: #6c757d;
}

.summary-booking-details a:hover {
  font-size: 14px;
  color: #4c5359;
}

.summary-booking-price {
  font-weight: bold;
  color: #28a745;
}

.summary-property-total,
.summary-client-total,
.summary-grand-total {
  font-weight: bold;
  font-size: 16px;
  color: #343a40;
  margin-top: 10px;
}

.summary-no-data {
  text-align: center;
  font-size: 16px;
  color: #868e96;
}

.summary-property-title a {
  text-decoration: none;
  color: #007bff;
  /* Primary blue color for visibility */
  font-weight: bold;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.summary-property-title a:hover {
  color: #0056b3;
  /* Darker blue for hover state */
  text-decoration: underline;
}

.summary-booking-summary {
  display: flex;
  flex-direction: row;
}

.summary-booking-time {
  color: rgb(41, 41, 41);
}

.off-peak-tag {
  color: #28a745;
  /* Green color */
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.clickable-row:hover {
  background-color: #ff5c040e;
}

.summary-modal {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 1000px;
  min-height: 400px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
}

.summary-modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.summary-modal-table th,
.summary-modal-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.summary-modal-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.summary-modal-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.summary-modal-table tr:hover {
  background-color: #f1f1f1;
}

.summary-modal-table td {
  text-align: center;
}

.summary-modal h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.summary-modal h3 a {
  color:black;
  text-decoration: none;
}

.summary-modal h3 a:hover {
  text-decoration: underline;
  color: rgb(6, 6, 98);
}

.close-modal-button {
  z-index: 100;
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff5a60;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-modal-button:hover {
  background-color: #ff3a40;
}

.select-all-text {
  font-size: 12px;
  font-weight: 500;
  color: #184474;
}

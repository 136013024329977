.add-booking-container {
  margin: 20px auto;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

.new-booking-form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select,
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  font-family: Arial, sans-serif;
}

textarea {
  resize: vertical;
  height: 100px;
}

.submit-button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.add-booking-container .delete-button {
  background-color: rgba(255, 0, 0, 0.643);
  width: 100%;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 70px;
}

.delete-button:hover {
  background-color: rgba(139, 0, 0, 0.583);
}

/* Toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #4CAF50;
}

input:focus+.slider {
  box-shadow: 0 0 1px #4CAF50;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded slider */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-group.laundry,
.form-group.amenities {
  flex: 0.4;
  /* This will make the laundry form group shorter */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-booking-popup {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px; 
  height: 70%;
  padding: 50px;
  overflow: scroll;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.edit-booking-popup .delete-button {
  background-color: rgba(255, 0, 0, 0.643);
  width: 100%;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 70px;
}

.popup-close {
  position: fixed;
  top: 15px;
  right: 18px;
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  transition: color 0.2s;
}

.popup-close:hover {
  color: #FF5A60;
}

.addons-list {
  margin-top: 1rem;
}

.addon-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.addon-item span {
  margin-right: 1rem;
}

.addon-quantity {
  width: 20%!important; /* Adjust the width to make it smaller */
  padding: 0.2rem; /* Reduce padding */
  margin-right: 1rem;
  text-align: center;
  font-size: 0.9rem; /* Adjust font size for better fit */
}

.addon-name {
  font-weight: bold;
}

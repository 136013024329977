.bookings-section {
    margin-top: 20px;
  }
  
  .bookings-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .booking-card {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }
  
  .booking-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .booking-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-right: 15px;
    text-align: center;
  }
  
  .date-day {
    font-size: 2rem;
    font-weight: bold;
    color: #4c7ac5;
  }
  
  .date-month-year {
    font-size: 0.9rem;
    color: #888;
  }
  
  .date-month-year span {
    display: block;
  }
  
  .booking-details-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1;
  }
  
  .booking-details-box p {
    margin: 0;
    flex-basis: calc(50% - 10px);
  }
  
  .booking-duration,
  .booking-type,
  .booking-status,
  .booking-cleaners {
    font-size: 0.9rem;
  }
  
  .booking-status span {
    font-weight: bold;
  }
  
  .pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  
  .pagination-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4c7ac5;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .pagination-button:hover {
    background-color: #3a5e99;
  }
  
  .pagination-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  } 

  .view-toggle {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #d3e1f8;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 5px;
  }
  
  .toggle-button.active {
    background-color: #4c7ac5;
    color: white;
    border-color: #4c7ac5;
  }
  
  .toggle-button:hover {
    background-color: #99adcd;
  }
  
  .toggle-button.active:hover {
    background-color: #3a5e99;
  }
  
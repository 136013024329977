.client-details-container {
    padding: 30px;
    background-color: #f9f9f9;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.client-info {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.client-details h3 {
    margin-bottom: 5px;
}

.client-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.client-info-row span {
    flex: 1 1 30%; /* Each span will take up approximately 30% of the row */
    margin-right: 10px;
}

.edit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.edit-button:hover {
    background-color: #0056b3;
}

.properties-table, .users-table{
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
}

.properties-table th, .properties-table td, .users-table th, .users-table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}

.properties-table th, .users-table th{
    font-weight: bold;
    background-color: #f9f9f9;
    color: #333;
}

.properties-table tr:hover, .users-table tr:hover {
    background-color: #f1f1f1;
}

.no-properties, .no-users{
    padding: 20px;
    background-color: #ffe6e6;
    border-radius: 8px;
    text-align: center;
    color: #cc0000;
    font-weight: bold;
}

.loading {
    text-align: center;
    font-size: 20px;
    padding: 50px;
}

.search-and-add-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Align vertically */
}

.search-bar {
    flex-grow: 1; /* Makes the search bar take up remaining space */
    margin-right: 10px; /* Space between the search bar and button */
}

.add-property-button, .add-new-client-user {
background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-property-button:hover, .add-new-client-user:hover {
    background-color: #0056b3;
}

.users-section {
    margin-top: 40px;
}

.properties-section-header, .users-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user-edit-icon {
    color: rgb(164, 164, 164);
    padding-left: 12px;
    padding-right: 12px;
}

.user-delete-icon {
    color: rgb(206, 0, 0);
}

.user-save-icon {
    color: rgb(4, 180, 4);
    font-size: 16px;
}

.user-cancel-icon {
    font-size: 16px;
}

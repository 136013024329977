.setup-photos-section {
  padding: 15px;
  border-radius: 6px;
  background-color: white;
}

.setup-photos-section h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.photos-grid {
  margin-top: 20px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 8px;
}

.photo-item {
  position: relative;
  width: 100px;
  height: 100px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  transition: transform 0.3s ease;
}

.photo-item:hover {
  transform: scale(1.05);
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

/* Modal Styling */
.photo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.photo-modal {
  max-width: 80%;
  max-height: 80%;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-modal {
  z-index: 100;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 20px;
  color: white;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
}

.close-modal:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

/* Placeholder for no photos */
.no-photos-message {
  text-align: center;
  color: #777;
  font-size: 16px;
  padding: 20px;
}

.placeholder-icon {
  font-size: 40px;
  margin-bottom: 10px;
  color: #bbb;
}

/* Upload Container */
.upload-container {
  text-align: center;
  margin-top: 20px;
}

/* Open Upload Button */
.open-upload-area-button {
  background-color: transparent;
  color: #28a745;
  border: 1px solid #28a745;
  padding: 12px 25px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.open-upload-area-button:hover {
  background-color: #28a745;
  color: white;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Display selected file names */
.selected-files {
  margin-top: 20px;
  text-align: center;
  /* Center the text in the field */
}

.selected-files h4 {
  margin-bottom: 10px;
  font-size: 16px;
}

.selected-files ul {
  list-style: none;
  padding: 0;
}

.selected-files ul li {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

/* Edit Photos Button */
.edit-photos-button {
  width: 100%;
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  min-width: 160px;
  /* Ensures buttons are of equal width */
  text-align: center;
  transition: background-color 0.3s ease;
}

.confirm-upload-apartment {
  background-color: #007bff;
  color: white;
  padding: 9px 25px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 15px;
}


.edit-photos-button:hover {
  background-color: #f0f8ff;
  color: #0056b3;
}

/* Upload Button */
.upload-button {
  background-color: transparent;
  color: #28a745;
  border: 1px solid #28a745;
  padding: 9px 25px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 15px;
}

.upload-button:hover {
  background-color: #28a7461f;
}

/* Delete Button - Dark Grey, Semi-transparent */
.delete-photo-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(75, 75, 75, 0.6);
  /* Darkish grey with transparency */
  border: 1px solid rgba(50, 50, 50, 0.8);
  /* Darker border */
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.delete-photo-button:hover {
  background-color: rgba(75, 75, 75, 0.9);
  /* More solid grey on hover */
  transform: scale(1.1);
}
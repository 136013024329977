/* Property Details Wrapper */
.property-details-wrapper {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

/* Header Section */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.header h1 {
    font-size: 24px;
    color: #333;
}

.edit-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #0056b3;
}

/* Property Details Section */
.property-details-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.details-card {
    background-color: white;
    padding: 12px 15px;
    border-radius: 6px;
}

.details-card h2 {
    font-size: 18px;
    color: #1f1f1f;
    margin-bottom: 20px;
    text-align: left;
}

.details-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detail-item {
    display: flex;
    margin-bottom: 5px;
}

.label {
    font-weight: bold;
    font-size: 14px;
    color: #555;
    margin-right: 10px;
}

.value {
    font-size: 14px;
    color: #333;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    min-height: 10px;
}

/* Full Width for Notes Section */
.full-width {
    grid-column: span 2;
}

/* Notes Section */
.notes-section {
    background-color: #fff;
    padding: 15px;
    border-radius: 6px;
}

.notes-row {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.note-item {
    display: flex;
    flex-direction: column;
}

.note-item .label {
    font-weight: bold;
    font-size: 14px;
    color: #555;
}

.note-item .value {
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-height: 150px;
    overflow-y: auto;
    margin-top: 5px;
}

/* Subtle Add Note Button */
.add-note-button {
    background-color: transparent;
    color: #007bff;
    border: 1px solid #007bff;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 15px;
    min-width: 160px; /* Ensures buttons are of equal width */
    text-align: center;
    transition: background-color 0.3s ease;
}

.add-note-button:hover {
    background-color: #f0f8ff;
    color: #0056b3;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.modal-content {
    background-color: white;
    max-width: 500px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    position: relative;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #555;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-button:hover {
    color: #000;
}

.detail-item a {
    text-decoration: none;
    font-weight: 600;
}

.detail-item  a:hover {
    text-decoration: underline;
    text-decoration-color: #114b8a;
}

.detail-item a span {
    color: #114b8a;
}

textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.google-maps-link-row {
    display: flex;
    align-items: center;
    margin-top: 5px;
    gap: 10px;
}

.google-maps-link {
    display: block;
    color: #114b8a;
    word-break: break-all;
    text-decoration: none;
}

.google-maps-link:hover{
    text-decoration: underline;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.2em;
    color: #114b8a;
    transition: color 0.3s ease;
    margin-top: 0px;
}

.icon-button:hover {
    color: #0056b3;
    background: white;
}

.property-details-wrapper {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .details-card {
    background-color: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }
  
  .photo-item {
    position: relative;
    cursor: pointer;
  }
  
  .thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    text-align: center;
  }
  
  .modal-image {
    max-width: 100%;
    height: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

/* Responsive Design */
@media (max-width: 768px) {
    .property-details-container {
        grid-template-columns: 1fr;
    }

    .details-row {
        flex-direction: column;
    }

    .detail-item {
        justify-content: flex-start;
        gap: 10px;
    }
}

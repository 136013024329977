.laundry-details-container {
    max-width: 800px;
    margin: 60px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Header Styling */
.laundry-details-container h2 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Client and Property Details Styling */
.client-property-details {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.detail-column {
    flex: 1;
    min-width: 250px;
}

.detail-column p {
    font-size: 14px;
    color: #555;
    margin: 8px 0;
}

/* Edit Button Styling */
.edit-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.edit-button:hover {
    background-color: #0056b3;
}

/* Laundry Details Table Styling */
.laundry-details-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: scroll;
}

.laundry-details-table th,
.laundry-details-table td {
    width: 1%; /* Ensures equal width for all columns */
    text-align: center; /* Keeps the text centered */
    padding: 12px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    word-wrap: break-word; /* Prevents text overflow */
}

.laundry-details-table th {
    background-color: #f1f1f1;
    font-weight: bold;
    color: #333;
}

.laundry-details-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.laundry-details-table tr:hover {
    background-color: #f1f1f1;
}


/* Highlight Mismatch in Red */
.mismatch {
    color: red;
    font-weight: bold;
}

.laundry-comment {
    margin-top: 50px;
}

.image-manager {
    margin-top: 50px;
}

/* Style for the photo item container */
.photo-item {
    position: relative;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Thumbnail styling */
.photo-item img.thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-bottom: 5px;
    transition: transform 0.3s;
}

.photo-item img.thumbnail:hover {
    transform: scale(1.05);
}

/* Badge Styling for Item Name in Top-Right Corner */
.photo-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    font-size: 0.7rem;
    /* Slightly larger font size */
    padding: 4px 8px;
    /* Increase padding for more readability */
    border-radius: 4px;
    font-weight: 500;
    /* Increase font weight for better readability */
    opacity: 0.9;
    pointer-events: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    /* Slight shadow for better contrast */
}

/* Conditional Background Colors */
.photo-badge.stain {
    background-color: #f7c948;
    /* Yellowish for stain */
}

.photo-badge.damage {
    background-color: #ff5a60;
    /* Reddish for damage */
}

/* Comment styling (optional text below images) */
.photo-comment {
    font-size: 0.9rem;
    color: #333;
    margin-top: 5px;
    text-align: center;
}

/* Flex layout for photo grid */
.photos-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.delete-photo-button {
    z-index: 100;
}

.mismatch-highlight {
    color: orange;
    font-weight: bold;
}

.warning-highlight {
    color: rgb(218, 103, 32);
    font-weight: bold;
}


th a {
    color: black;
}

th a:hover {
    color:#0056b3;
}

@media (max-width: 768px) {
    .laundry-details-container {
        padding: 20px;
        border-radius: 8px;
    }

    .laundry-details-container .header {
        flex-direction: column;
    }

    .laundry-details-container h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .client-property-details {
        flex-direction: column;
        gap: 15px;
    }

    .detail-column {
        min-width: 100%;
        /* Full width for smaller screens */
    }

    .detail-column p {
        font-size: 13px;
        /* Slightly smaller font for details */
    }

    .laundry-details-container .edit-button {
        position: unset;
        width: 100%;
        /* Full-width button for mobile */
        font-size: 12px;
        padding: 10px;
        margin-bottom: 15px;
        /* Add spacing below the button */
    }

    .laundry-details-table {
        display: block;
        /* Prevent text wrapping in cells */
        -webkit-overflow-scrolling: touch;
        /* Smooth scrolling on iOS */
    }

    .laundry-details-table th {
        position: relative;
        font-size: 12px;
        padding: 8px;
        text-align: center; /* Ensure text is centered horizontally */
        height: 100%; /* Ensure full height for vertical centering */
    }

    /* Hide the original text while keeping its space */
    .laundry-details-table th span {
        visibility: hidden;
        display: inline-block;
    }

    /* Style for replacement text in the pseudo-element */
    .laundry-details-table th[data-mobile-label]::before {
        content: attr(data-mobile-label);
        position: absolute;
        top: 50%; /* Move the pseudo-element to the middle of the cell */
        left: 50%;
        transform: translate(-50%, -50%); /* Center it horizontally and vertically */
        visibility: visible;
        text-align: center;
        font-size: 12px;
    }

    .laundry-comment {
        font-size: 14px;
        /* Slightly smaller comment font */
        margin-top: 30px;
    }

    /* Photos Grid for Mobile */
    .photos-grid {
        flex-direction: column;
        /* Stack photos vertically */
        gap: 15px;
        align-items: center;
        /* Center align the photos */
    }

    .photo-item {
        width: 80%;
        /* Reduce photo item width */
        margin: 0 auto;
    }

    .photo-item img.thumbnail {
        width: 100%;
        /* Full width of its container */
        height: auto;
        /* Maintain aspect ratio */
    }

    .photo-badge {
        font-size: 0.65rem;
        /* Reduce badge font size */
        padding: 3px 6px;
    }

    .delete-photo-button {
        font-size: 12px;
        /* Smaller delete button */
    }

    .image-manager {
        margin-top: 30px;
    }
}
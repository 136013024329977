.add-report-popup {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 800px; 
    padding: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }

.booking-report-form {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.booking-report-form label{
    display: flex;
    margin-bottom: 10px;
}


.report-select {
    width: 100%;
}

.report-input, .report-textarea, .report-file-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.report-textarea {
    min-height: 100px;
    resize: vertical;
}

.report-submit-button {
    background-color: #FF5A60;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
}

.report-submit-button:hover {
    background-color: #e04b50;
}

.report-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.sq-popup {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    width: 500px;
    padding: 20px;
    min-height: 200px;
    max-width: 90%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.popup h3 {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
}

.loading-feedback {
    text-align: center;
}

.standard-quantity-container {
    margin-bottom: 15px;
}

.remove-extra-item-button {
    background-color: transparent;
    color: grey;
    font-size: 22px;
    padding: 0px
}

.remove-extra-item-button:hover {
    color: #d9534f;
}

.remove-button {
    color: #fff;
    background-color: #ff5a60;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.remove-button:hover {
    background-color: #d9534f;
}

.add-item-section {
    margin: 20px 0;
    text-align: center;
}

.standard-quantity-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    gap: 10px;
}


.popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.cancel-button,
.save-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background: #ea3d3d;
}

.save-button {
    background: #28a745;
    color: #fff;
}

.save-button:hover {
    background: #218838;
}
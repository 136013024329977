.warehouse-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.date-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-input {
  margin-left: 10px;
  padding: 5px;
}

.fetch-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4c7ac5;
  color: white;
  border: none;
  cursor: pointer;
}

.fetch-button:hover {
  background-color: #3a5e9f;
}

.report-output {
  margin-top: 20px;
}

.warehouse-entry {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.entry-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.entry-details,
.entry-duty {
  margin: 5px 0;
}

.error-message {
  color: red;
  font-weight: bold;
}

.laundry-link {
  color: black;
  text-decoration: none;
}

.laundry-link:hover {
  color: rgb(25, 76, 91);
}

.entry-title {
  text-decoration: none;
}

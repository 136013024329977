.booking-details-wrapper {
  padding: 50px;
  margin: auto;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.booking-buttons {
  display: flex;
  gap: 30px;
}

.edit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: solid;
  border-width: 1px;
  border-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #0056b3;
}

.add-report-button {
  background-color: #ffffff;
  color: #007bff;
  padding: 10px 20px;
  border: solid;
  border-width: 1px;
  border-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-report-button:hover {
  background-color: #deeeff;
}

.booking-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns */
  gap: 20px;
}

.booking-section {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.unit-display {
  text-align: left;
}

.booking-section p {
  margin: 5px 0;
}

.notes-section {
  grid-column: 1 / -1;
  /* Make notes section span the full width */
}

.setup-photos-section {
  grid-column: 1 / -1;
}

/* 📌 Booking Reports Section */
.reports-section {
  grid-column: 1 / -1;
  padding: 20px;
}

.info-link {
  text-decoration: none;
  color: inherit;
}

.info-link:hover {
  text-decoration: underline;
  color: #007BFF;
  /* Change to the color you want for hover */
}

.permanent-notes {
  white-space: pre-wrap;
}
/* Main container */
.add-laundry-container {
    margin: 40px auto;
    padding: 30px;
    width: 100%;
    max-width: 900px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  
  .select-booking, 
  .select-extra-item, 
  .quantity-input, 
  textarea {
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
  }
  
  textarea {
    resize: none;
  }
  
  /* Lists section */
  .laundry-lists-container {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .list-section {
    width: 50%;
  }
  
  .laundry-lists-container h3, .laundry-lists-container h4 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #FF5A60;
    padding-bottom: 10px;
  }
  
  .item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .item-name {
    font-size: 16px;
    color: #666;
    width: 60%;
  }
  
  .quantity-input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
  }
  
  /* Extra item selection */
  .extra-item-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .select-extra-item {
    width: 60%;
    margin-right: 10px;
  }
  
  .add-extra-item-button {
    background-color: #FF5A60;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-extra-item-button:hover {
    background-color: #e04b52;
  }
  
  /* Extra items list */
  .extra-items-list {
    margin-top: 15px;
  }
  
  /* Comment and Submit button */
  .comment-field {
    padding: 12px;
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .submit-button {
    width: 100%;
    padding: 15px;
    background-color: #FF5A60;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #e04b52;
  }

  /* Booking details container for side-by-side display */
.booking-details {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-size: 16px;
  color: #555;
}

/* Individual columns within the booking details */
.booking-details .column {
  flex: 1;
}

.booking-details p {
  margin: 5px 0;
}
  

  
  .client-booking-card {
    display: flex;
    flex-direction: column;
    background: #ffd2d306;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    gap: 20px
  }
  
  .client-booking-card h2 {
    text-align: center;
    color: #FF5A60;
    margin-bottom: 10px;
  }
  
  .client-booking-subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .client-section {
    padding: 15px 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .client-booking-info p,
  .client-booking-notes p {
    font-size: 16px;
    color: #333;
    margin: 10px 0;
  }
  
  .client-booking-info p strong,
  .client-booking-notes p strong {
    color: #000;
  }
  
  .client-booking-subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .client-error-message {
    color: red;
    font-weight: bold;
    text-align: center;
  }
  
  .client-loading {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #666;
  }

  .client-booking-notes {
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(58, 58, 58);
    margin-bottom: 40px;
  }
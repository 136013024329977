.home-dashboard {
  padding: 2rem;
  text-align: center;
}

.home-dashboard h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.home-dashboard p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.home-dashboard .stats-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-dashboard .card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  padding: 1.5rem;
  margin: 1rem;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease;
}

.home-dashboard .card:hover {
  transform: translateY(-10px);
}

.home-dashboard .card-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.home-dashboard .card-info h3 {
  font-size: 1.5rem;
  margin: 0;
}

.home-dashboard .card-info p {
  font-size: 1.2rem;
  color: #777;
}
